import React, { Component } from 'react'

export default class JoinSuccess extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        timer: 10,
        isDisplayed: true
      }
    }

    componentDidMount = () =>  {
        this.startTimer()
    }

    startTimer = () => {
        this.timer = setInterval(() => {
            if (this.state.timer <= 0) {
                return this.stopTimer()
            }

            this.setState({
                isDisplayed: false
            }, () => {
                this.setState({
                    timer: this.state.timer - 1,
                    isDisplayed: true
                })
            })
        }, 1000);
        
    }

    stopTimer = () => {
        clearInterval(this.timer);
        this.props.toggleSubmitted(false)
    }

    render() {
        return (
            <div className='success-container'>
                <div className='success-icon' />
                <div className='success-content'>
                    {this.props.message}
                </div>

                <div className='success-content'>
                    <div>
                        you will be redirected in :
                    </div>
                    {
                        this.state.isDisplayed &&
                        <div className='timer'>
                            {this.state.timer}
                        </div>
                    }
                </div>
            </div>
        )
    }
}
